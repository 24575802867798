<div class="header-container p-2">
  <div class="logo-container" style="width: 70px;">
    <!-- <a class="logo" href="#" (click)="navigateHome()">Intelcode</a> -->
    <!-- <img height="50px" src="assets/logo.png" /> Freecode -->
    <a class="navbar-brand nbb" href="#">
      CodeFree
      <!-- <img (click)="navigateHome()" height="50px" src="assets/logo.png" /> -->
    </a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
      <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
    </nb-select> -->
</div>

<!-- <div class="header-container">
    <nb-actions size="small">
  
      <nb-action class="control-item">
        <nb-search type="rotate-layout"></nb-search>
      </nb-action>
      <nb-action class="control-item" icon="email-outline"></nb-action>
      <nb-action class="control-item" icon="bell-outline"></nb-action>
      <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
        <nb-user [nbContextMenu]="userMenu"
                 [onlyPicture]="userPictureOnly"
                 [name]="user?.name"
                 [picture]="user?.picture">
        </nb-user>
      </nb-action>
    </nb-actions>
  </div> -->

<div class="header-container">
  <div class="m-2">
  </div>
</div>

<section class="ftco-section" style="height: 100vh;">
  <div class="container">
    <div class="row justify-content-center mb-5 pb-5 mt-5">

      <div class="col-lg-4 col-md-6 col-sm-12">

        <router-outlet></router-outlet>

      </div>
    </div>
  </div>
</section>