import { ChangeDetectorRef, Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { EventService } from '../emmiter/event.service';
import { ToastrService } from 'ngx-toastr';
import * as jQuery from "jquery";
import { ApplicationRef } from '@angular/core';
declare const $:any;
@Injectable({
  providedIn: 'root'
})
export class FormServiceService {
 
  constructor(private apiService:ApiService,private eventService:EventService,private toastr:ToastrService,private refDetectChange:ApplicationRef) {

  }

  activatedAjaxFormSubmit(selector=".form,.form_",eventCode=303){
    console.log('Form activated : ' + selector);
    let t = this;
    // jQuery(document).ready(function(){
      $(document).on('submit',selector,function(e){ 
        var form = jQuery(this);
        jQuery(form).find('.role_status').show();
        jQuery(form).find('.role_submit').hide();
        let action = form.attr('action');
        if(!form.attr('action').startsWith("http")){
          action = t.apiService.route+ action;
        }
        jQuery(".error_message").hide();
      e.preventDefault();
      var fData = new FormData(this);
      jQuery(form).find('button').attr('disabled','disabled');
      jQuery.ajax({
          url   : action,
          type  : form.attr('method'),
          data  : fData,
          contentType: false,
          processData: false,
          error:function(error){
            jQuery(form).find('.role_status').hide();
            jQuery(form).find('.role_submit').show();
            jQuery(form).find('button').removeAttr('disabled');
            jQuery(form).find('button').text('Submit');
          },            
          success: function(response){
            
            jQuery(form).find('.role_status').hide();
            jQuery(form).find('.role_submit').show();
            jQuery(form).find('button').removeAttr('disabled');
              if("errors" in response){
                  jQuery.each(response['errors'], function(key:any,value){
                      key = key.replace(/\./g,"-");
                      jQuery(document).find("#" + key + "-error").show();
                      jQuery(document).find("#" + key + "-error").html(value[0]);
                      jQuery(document).find("." + key + "-err").show();
                      jQuery(document).find("." + key + "-err").html(value);
                      console.log(key,value[0],"." + key + "-err");
                  });}
              else {
                if(response.status){
                  if(!jQuery(form).hasClass("exclude_clear")){
                    jQuery(form)[0].reset();
                  }  
                  t.toastr.success(response.message);                 
                  t.eventService.addEvent("Form Submitted : "+selector,eventCode);
                }
                else{
                  t.toastr.error(response.message);
                  setTimeout(function(){
                    t.refDetectChange.tick();
                  },500);
                }
              }
              t.refDetectChange.tick();
              setTimeout(function(){
                t.refDetectChange.tick();
              },500);          
            },
            // err:function(message){
            //   jQuery(form).find('button').attr('disabled','disabled');
            // }
            });
      return false;
     });
  // });
  }

  dectivatedAjaxFormSubmit(selector: string) {
    $(document).off('submit',selector);
    console.log('Form dectivated : ' + selector);
  }

}
