<div class="content" role="main">
  <div class="card highlight-card card-small">
  </div><br><br>
    <form #checkout="ngForm" (ngSubmit)="onSubmit(checkout)" class="checkout">
        <div class="form-row">
            <label for="card-info">Card Info</label>
            <div id="card-info" style="width: 100%;" #cardInfo></div>
            <div id="card-errors" role="alert" *ngIf="error">{{ error }}</div>
        </div>
        <button type="submit">Pay £1200</button>
    </form>

</div>
