import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
 
  `,
})
export class FooterComponent {
  loginAsDataManager() {}
}
