<div class="row">
    <div class="col-md-8 col-sm-12">
        <div class="tabbable">
                    <div class="move-container">
<div class="w3-row w3-green w3-padding">
<div style="width:100px">
  <button _ngcontent-vwm-c384="" (click)="backClicked()" class="w3-left btn w3-white">Back</button>
</div>
</div>

<ul class="w3-row w3-margin w3-padding">
<li *ngFor="let item of table_data.vrs|keyvalue">
  <p class="w3-half"> {{item.key}}</p>
  <button class="btn" [attr.data-move]="'/edit/variable/'+table_data.module+'/'+item.key">Edit/View Definition</button>
</li>
</ul>

<form action="/save/new/vr" method="post">
<p class="w3-green">Create New Variable</p>
<input type="text" name="v_name" placeholder="Remarkable name">
<input type="hidden" name="module" value="{{table_data.module}}">
<select name="filter">
<option>Select Filter</option>
  <option *ngFor="let i of table_data.filters">{{i}}</option>
</select>

<select name="column">
<option>Select column</option>
   <option *ngFor="let item of table_data.fields">{{item}}</option>
</select>

<select name="function" class="jn_select_switch">
<option>avg</option>
<option>count</option>
<option>countnull</option>
<option>countnotnull</option>
<option>max</option>
<option>min</option>
<option>getfirst</option>
</select>

<button class="btn">Create</button>
</form>
</div>
        </div>
    </div>
</div>