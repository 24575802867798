import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChangeDetectorRef, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {NbMenuModule,NbSidebarModule,NbWindowModule} from '@nebular/theme';
import { ToastrModule } from 'ngx-toastr';
import { IonicModule } from '@ionic/angular';
import { CustomHttpInterceptor } from './shared/http-interceptor/CustomHttp.interceptor';
import { AuthService } from './shared/auth/auth.service';
import { EventService } from './shared/emmiter/event.service';
// import { CustomHtmlComponent } from './view-creator/custom/custom-html/custom-html.component';
import { ContainerComponent } from './modules/auth/container/container.component'; 
import { ViewManagerComponent } from './components/view-manager/view-manager.component';
import { PaymentComponent } from './payment/payment.component';
import { FormsModule } from '@angular/forms';
import { VerifyAccountComponent } from './modules/auth/verify-account/verify-account.component';
// import { Kommunicate } from '@ionic-native/kommunicate/ngx';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { AnalyticsService } from './@core/utils';
import { FilterComponent } from './modules/database-module/filter/filter.component';
import { DataTransactionComponent } from './modules/database-module/data-transaction/data-transaction.component';
import { FormServiceService } from './shared/form-service/form-service.service';
import { SubscriptionUsagesComponent } from './components/subscription-usages/subscription-usages.component';
import { ViewSettingComponent } from './cog-components/view-setting/view-setting.component';
import { FormSettingComponent } from './cog-components/form-setting/form-setting.component';

@NgModule({
  declarations: [
                 AppComponent, 
                //  CustomHtmlComponent, 
                 ContainerComponent,
                 PaymentComponent,
                 ViewManagerComponent,
                 VerifyAccountComponent,
                 FilterComponent,
                 DataTransactionComponent,
                 SubscriptionUsagesComponent,
                 FormSettingComponent
                 ],
  imports: [
    BrowserModule,
    NgxIntlTelInputModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    // NbDatepickerModule.forRoot(),
    // NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    // NbToastrModule.forRoot(),
    CoreModule.forRoot(), 
    IonicModule.forRoot(),
    ThemeModule.forRoot(),
     ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    EventService.forRoot(),
    CustomHttpInterceptor.forRoot()
  ],
  providers:[
    AuthService,
    AnalyticsService,
    FormServiceService,
    // ,
    // Kommunicate
    // EventService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: CustomHttpInterceptor,
    //   multi: true
    // } 
  ], 
  bootstrap: [AppComponent],
})
export class AppModule {
}
