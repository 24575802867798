import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from '../../../shared/api/api.service';
import { EventService, MyServiceEvent } from '../../../shared/emmiter/event.service';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from '../../../shared/token/token.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any = 9;
  loading = 0;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';
  data: any = { projects: [] };
  working_project: any;
  hideMenuOnClick = false;


  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];
  show_new_project_option = true;
  headerLoaded = false;
  es: any;
  building = false;
  page_path = '';

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private toastr: ToastrService,
    private breakpointService: NbMediaBreakpointsService,
    private router: Router,
    private apiService: ApiService,
    private eventService: EventService,
    private layoutService: LayoutService){

    this.apiService.ajaxSetting();
    this.user_check();
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log(event.url);
        this.page_path = event.url;
        // this.user_check();

        if (event.url === "/publish") {
          this.loadDB();
        }

        if (event.url !== "/dashboard") {
          this.show_new_project_option = true;
        }
        else {
          this.show_new_project_option = false;
        }
      }
    });

    this.es = this.eventService.onChange.subscribe({
      next: (event: MyServiceEvent) => {
        if (event.eventId == 309) {
          this.loadDB();
        }
        if (event.eventId == 90) {
          this.building = true;
        }

        if (event.eventId == 91) {
          this.loadDB();
          toastr.success("Build worker job finished!");
          this.building = false;
        }

        if (event.eventId == 5000) {
          this.loading = this.loading + 1;
        }
        if (event.eventId == 6000) {
          if (this.loading > 0) {
            this.loading = this.loading - 1;
          }
        }
        console.log(this.loading);
      }
    });
  }

  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.router.url]);
  }

  moveTo(url) {
    this.router.navigateByUrl(url);
  }

  user_check() {
    this.apiService.getData('/user')
      .subscribe((res: any) => {
        this.user = res.user;
        if (res.user === 0) {
          if (this.page_path !== '/') {
            // this.router.navigateByUrl('/login');
          }
        }
        else {
          // this.router.navigateByUrl('/dashboard');
          this.headerLoaded = true;
        }
      },
        (err: any) => {
          if (this.page_path !== '/') {
            // this.router.navigateByUrl('/login');
          }
        });
  }

  new_project() {
    this.router.navigateByUrl('/dashboard/new-project');
  }

  publishPage() {
    this.router.navigateByUrl("/userarea/publish");
  }

  loadDB() {
    this.apiService.getData("/admin").subscribe((res: any) => {
      console.log(res);
      this.data = res;
      // localStorage.setItem('project_id',this.data.active_project.id);
      // localStorage.setItem('project_name',this.data.active_project.project_name);
      this.apiService.ajaxSetting();
    });
  }

  logout() {
    this.apiService.getData('/logout')
      .subscribe((res: any) => {
        localStorage.removeItem("token");
        this.router.navigateByUrl('/login');
      },
        (err: any) => {
        });
  }
  dashboard() {
    this.router.navigateByUrl('/dashboard');
  }

  ngOnInit() {
    const { xl } = this.breakpointService.getBreakpointsMap();
    const { is } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint),
        takeUntil(this.destroy$),
      )
      .subscribe(currentBreakpoint => {
        this.userPictureOnly = currentBreakpoint.width < xl;
        this.hideMenuOnClick = currentBreakpoint.width <= is;
      });
    // ...
    this.menuService.onItemClick().subscribe(() => {
      if (this.hideMenuOnClick) {
        this.sidebarService.collapse('menu-sidebar');
      }
    });
    // this.apiService.getData("/test").subscribe((res:any)=>{
    //   console.log('.........Test...........')
    //   console.log(res);
    //   console.log('.........Test...........')
    // });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.es.unsubscribe();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

}
