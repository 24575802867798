import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../shared/api/api.service';
import { EventService } from '../../../shared/emmiter/event.service';
import * as $ from "jquery";
import { FormServiceService } from '../../../shared/form-service/form-service.service';
@Component({
  selector: 'ngx-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {

  constructor(private apiService:ApiService,private formService:FormServiceService,private eventService:EventService,private toaster:ToastrService,private refDetectChange:ChangeDetectorRef) { }

  ngOnInit(): void {
    // let t = this;
    // this.formService.activatedAjaxFormSubmit("form.auth_forms",303);
  //   $(document).on('submit',"form.auth_forms",function(e){ 
  //     var form = $(this);
  //     $(form).find('.role_status').show();
  //     $(form).find('.role_submit').hide();
  //     let action = form.attr('action');
  //     if(!form.attr('action').startsWith("http")){
  //       action = t.apiService.route+ action;
  //     }
  //   $(".error_message").hide();
  //   e.preventDefault();
  //   var fData = new FormData(this);
  //   $.ajax({
  //       url   : action,
  //       type  : form.attr('method'),
  //       data  : fData,
  //       contentType: false,
  //       processData: false,
  //       error:function(error){
  //         $(form).find('.role_status').hide();
  //         $(form).find('.role_submit').show();
  //       },            
  //       success: function(response){
  //         console.log('app.component form submitted!');
  //         $(form).find('.role_status').hide();
  //         $(form).find('.role_submit').show();
  //           if("errors" in response){
  //               $.each(response['errors'], function(key:any,value){
  //                   key = key.replace(/\./g,"-");
  //                   $(document).find("#" + key + "-error").show();
  //                   $(document).find("#" + key + "-error").html(value[0]);
  //                   $(document).find("." + key + "-err").show();
  //                   $(document).find("." + key + "-err").html(value);
  //                   console.log(key,value[0],"." + key + "-err");
  //               });}
  //           else {
  //             if(response.status){
  //               t.toaster.success(response.message);
  //               t.eventService.addEvent("Form Submitted",303);
  //             }
  //             else{
  //               t.toaster.error(response.message);
  //               setTimeout(function(){
  //                 t.refDetectChange.detectChanges();
  //               },500);
  //             }
  //           }
  //           t.refDetectChange.detectChanges();
  //           setTimeout(function(){
  //             t.refDetectChange.detectChanges();
  //           },500);             
  //         }
  //         });
  //   return false;
  //  });
  }

  // ngOnDestroy(){
  //   // console.log("Leaving on app.component");
  //   // $(document).off('submit','form.auth_forms');
  //   this.formService.activatedAjaxFormSubmit("form.auth_forms",303);
  //   this.formService.dectivatedAjaxFormSubmit('form.auth_forms');
  // }

}
