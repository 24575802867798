import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../shared/api/api.service';
import * as jQuery from "jquery";
declare const $:any;
@Component({
  selector: 'ngx-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  module :any;
  type:any;
  constructor(private apiService:ApiService,private activatedRoute:ActivatedRoute){
    this.module = activatedRoute.snapshot.params.name;
    this.type = activatedRoute.snapshot.params.type;
   }

   backClicked(){
    this.apiService.backClicked();
  }

  ngOnInit(): void {
    let that =  this;
    $(".o-top_button").find("form").remove();
   let url = that.apiService.route+"/module/create_form/"+this.type+"/"+that.module;

   console.log(url);
   jQuery.get(url,function(responseTxt){
      // console.log(responseTxt);
      jQuery('#dialog_content').html(responseTxt);
      
      $("#sorting").sortable();
    }) 
  }

}
