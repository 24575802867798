<div class="col-md-12 col-sm-12">
    
    <div class="modern_header w-100">
        <a href="javascript:void(0);" (click)="backClicked()"
            class=" mr-1 spectrum-Button spectrum-Button--fill spectrum-Button--sizeS spectrum-Button--staticWhite"
            role="button" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-arrow-left">&nbsp;Back</i>
        </a>
        <a href="javascript:void(0);" (click)="view_type = 'create'"
            class=" mr-1 spectrum-Button spectrum-Button--fill spectrum-Button--sizeS spectrum-Button--staticWhite float-right"
            role="button" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-plus">&nbsp;New</i>
        </a>
    </div>

    <div class="card">
      <div class="card-content card-body">
        <div *ngIf="view_type === 'list'">
            <p class="text-center">Transction Rules</p>
            <table class="table mx-auto">
                <tbody>
                    <tr *ngFor="let item of table_data?.dts|keyvalue">
                        <td>{{item.key}}</td>
                        <td>       
                            <button class="spectrum-Button spectrum-Button--outline spectrum-Button--sizeS spectrum-Button--accent"  (click)="editRule(item.key)">Edit/View Definition</button>
                        </td>
                    </tr>
                </tbody>
            </table>
           </div>
       
    
       <div *ngIf="view_type === 'create'">
        <button (click)="view_type = 'list'"
                class="spectrum-Button spectrum-Button--accent spectrum-Button--outline mb-2 spectrum-Button--sizeS mr-1"
                role="button">
                <i class="fa fa-arrow-left">&nbsp;List Rules</i>
       </button>
        <form action="/save/new/dt" class="dashboard" method="post"  *ngIf="view_type === 'create'">
            <p class="text-center">New transaction rule</p>
            <div class="row">
                <div class="col-md-4 col-12">
                    <div class="form-group">
                        <label>Name</label>
                        <input type="text" class="form-control" name="v_name" placeholder="Remarkable name">
                        <p class="error_message v_name-err w-100"></p>
                    </div>
                </div>
        
            <div class="col-md-4 col-12">
                <div class="form-group">
                    <label>Column</label>
                    <select class="form-control" name="column">
                        <option>Select column</option>
                        <option *ngFor="let item of table_data.fields">{{item}}</option>
                        </select>
                        <p class="error_message column-err w-100"></p>
                </div>
            </div>
    
            <div class="col-md-4 col-12">
                <div class="form-group">
                    <label>Filter</label>
                    <select class="form-control" name="filter">
                        <option>No Filter</option>
                                            <option *ngFor="let item of table_data.filters">{{item}}</option>
                                    </select>
                                    <p class="error_message filter-err w-100"></p>
                </div>
            </div>
             <div class="col-md-4">
                <label>Transaction Type</label>
                 <select name="data_type" class="jn_select_switch form-control">
                     <option>Update with</option>
                     <option>value</option>
                     <option>toggle</option>
                 </select>
                 <p class="error_message data-err w-100"></p>
                 <br>
                 <div class="hide_where">
                     <div class="value" style="display:none">
                        <label>Transaction Data</label>
                         <input type="text" class="form-control" name="value" placeholder="Enter value">
                     <p class="error_message value-err w-100"></p>
                        </div>
                 </div>

                 <div class="hide_where">
                     <div class="toggle" style="display:none">
                        <label>Transaction Data</label>
                         <input class="form-control" name="toggle" placeholder="Enter comma separated values">
                         <p class="error_message toggle-err w-100"></p>
                     </div>
                 </div>
             </div>
            </div>
             <input type="hidden" name="module" value="{{module}}">    
            <button class="spectrum-Button spectrum-Button--outline spectrum-Button--sizeS spectrum-Button--accent">Create</button>
        </form>
       </div>
       <div  *ngIf="view_type === 'edit'" >
        <button (click)="view_type = 'list'"
        class="spectrum-Button spectrum-Button--accent spectrum-Button--outline mb-2 spectrum-Button--sizeS"
        role="button">
        <i class="fa fa-arrow-left">&nbsp;List Rules</i>
</button>
<div id="edit"></div>
       </div>
      </div>
    </div>
    </div>