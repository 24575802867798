import { ChangeDetectorRef, Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../shared/api/api.service';
import { EventService, MyServiceEvent } from '../../shared/emmiter/event.service';
import { MENU_ITEMS } from './pages-menu';
import { NbIconLibraries } from '@nebular/theme';
import Pusher from 'pusher-js';
import { Subject, Observable } from 'rxjs';
import * as $ from "jquery";
import { FormServiceService } from '../../shared/form-service/form-service.service';

@Component({
  selector: 'ngx-pages',
  styleUrls: ['pages.component.scss'],
  template: `
    <ngx-one-column-layout>
      <nb-menu [items]="menu"></nb-menu>
      <router-outlet></router-outlet>
    </ngx-one-column-layout>`,
})

export class PagesComponent {
  loading = true;
  private pusherClient: Pusher;
  menu = MENU_ITEMS;
  private channel;
  private subject: Subject<any> = new Subject<any>();

  constructor(private refDetectChange:ChangeDetectorRef,private formServie:FormServiceService,private iconLibraries: NbIconLibraries,
    private eventService:EventService,private toaster:ToastrService,private apiService:ApiService){

      // Pusher.logToConsole = true;
      // this.pusherClient = new Pusher('6372b1f6ecb0fb5583d0', { cluster: 'ap2' });
      //   let channel_name = localStorage.getItem('project_name');
      //   console.log(channel_name,'...................');
      //   this.channel = this.pusherClient.subscribe(channel_name);
    
      //   this.channel.bind(
      //     'build_started',
      //     (data: { message: string; body: string; time: string }) => {
      //       console.log(data);
      //       eventService.addEvent("build_started",90);
      //     }
      //   );

      //   this.channel.bind(
      //     'build_finished',
      //     (data: { message: string; body: string; time: string }) => {
      //       console.log(data);
      //       eventService.addEvent("build_finished",91);
      //     }
      //   );

      this.iconLibraries.registerSvgPack('nav-bar', {
        'database':`
        <?xml version="1.0" standalone="no"?>
        <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
         "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="18pt" height="18pt" viewBox="0 0 512.000000 512.000000"
         preserveAspectRatio="xMidYMid meet">
        
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M3260 5111 c-83 -26 -151 -93 -174 -172 -13 -43 -16 -111 -16 -360
        l0 -307 -157 -4 c-174 -5 -203 -12 -267 -68 -20 -18 -47 -52 -59 -74 -22 -41
        -22 -42 -25 -758 l-3 -718 -169 0 -169 0 -3 523 c-3 602 4 557 -108 660 -235
        218 -877 319 -1429 226 -365 -61 -608 -191 -667 -356 -11 -31 -13 -236 -14
        -1124 l0 -1085 26 -52 c72 -146 313 -252 699 -309 185 -27 611 -24 800 5 395
        62 641 184 685 340 6 23 10 216 10 515 l0 477 170 0 169 0 3 -718 c3 -716 3
        -717 25 -758 12 -22 39 -56 59 -74 64 -56 93 -63 267 -68 l157 -4 0 -307 c0
        -348 5 -384 67 -452 20 -22 55 -50 77 -62 l41 -22 840 0 c800 0 842 1 875 19
        49 26 98 74 123 121 21 39 22 52 25 434 3 435 1 451 -59 524 -17 21 -50 48
        -72 60 -41 21 -53 22 -439 27 l-398 5 -23 25 c-16 17 -28 46 -35 88 -21 122
        -93 198 -209 222 -35 8 -153 10 -336 8 l-282 -3 -51 -27 c-114 -60 -144 -132
        -144 -346 l0 -144 -146 4 c-146 3 -146 3 -170 31 l-24 28 0 695 0 694 170 0
        170 0 0 -223 c0 -270 7 -305 80 -377 83 -83 41 -80 945 -80 882 0 860 -2 936
        67 22 20 50 55 62 77 22 40 22 49 22 456 0 408 0 416 -22 456 -12 22 -39 56
        -59 74 -75 65 -70 64 -500 70 -452 6 -427 0 -439 99 -8 70 -50 149 -99 186
        -65 50 -105 55 -411 55 -335 0 -361 -5 -435 -80 -73 -72 -80 -107 -80 -377 l0
        -223 -170 0 -170 0 0 694 0 695 24 28 c24 28 24 28 170 31 l146 4 0 -144 c0
        -214 27 -280 141 -343 l54 -30 830 0 c739 0 834 2 865 16 52 24 97 65 128 118
        l27 46 0 415 c0 406 0 416 -22 455 -25 47 -74 95 -123 121 -32 17 -70 19 -432
        24 -354 4 -399 7 -414 22 -9 9 -23 49 -31 90 -15 81 -44 133 -98 173 -67 52
        -87 54 -410 54 -165 -1 -311 -4 -325 -8z m622 -172 c24 -13 48 -67 48 -107 0
        -73 65 -161 145 -198 50 -24 53 -24 433 -24 l384 0 29 -29 29 -29 0 -367 c0
        -243 -4 -373 -11 -387 -26 -48 -26 -48 -844 -48 -836 0 -820 -1 -844 56 -8 22
        -11 166 -9 563 l3 533 28 24 28 24 280 0 c182 0 287 -4 301 -11z m-2576 -1024
        c298 -15 602 -111 704 -221 45 -49 48 -65 21 -110 -55 -91 -259 -177 -531
        -225 -148 -26 -459 -36 -625 -20 -303 30 -551 109 -657 212 -53 50 -59 77 -30
        122 59 89 282 182 537 223 109 17 338 32 425 28 30 -2 101 -6 156 -9z m-928
        -645 c451 -164 1183 -142 1575 48 l97 48 0 -238 0 -237 -30 -34 c-60 -69 -238
        -136 -478 -179 -114 -20 -158 -23 -432 -23 -276 0 -317 2 -434 23 -234 43
        -388 98 -465 167 l-41 37 0 243 0 243 65 -35 c36 -19 100 -47 143 -63z m3501
        -125 c31 -15 51 -59 51 -107 0 -62 56 -145 128 -190 37 -23 42 -23 440 -28
        l404 -5 24 -28 24 -28 0 -369 0 -369 -24 -28 -24 -28 -806 0 -805 0 -23 23
        -23 23 -2 537 c-3 576 -3 574 47 599 39 20 551 18 589 -2z m-3683 -494 c20
        -20 165 -74 269 -101 480 -124 1176 -87 1519 81 32 16 60 29 62 29 2 0 4 -103
        4 -229 l0 -229 -32 -33 c-75 -75 -302 -151 -553 -185 -179 -24 -531 -24 -710
        0 -247 34 -473 109 -552 183 l-33 31 0 231 c0 127 4 231 8 231 4 0 12 -4 18
        -9z m184 -759 c211 -67 432 -96 735 -96 371 1 641 48 863 149 l72 34 0 -225
        c0 -141 -4 -233 -11 -245 -19 -36 -125 -95 -236 -132 -346 -115 -892 -128
        -1280 -32 -164 41 -301 105 -337 157 -13 19 -16 58 -16 250 l0 228 58 -29 c31
        -16 100 -42 152 -59z m3514 -551 c17 -16 27 -39 31 -70 14 -109 66 -184 154
        -225 44 -20 66 -21 438 -26 359 -5 394 -7 410 -23 17 -16 18 -48 21 -393 l3
        -375 -30 -29 -29 -30 -796 0 -795 0 -28 24 -28 24 -3 533 c-2 397 1 541 9 563
        22 52 47 56 346 53 271 -2 271 -2 297 -26z"/>
        <path d="M3463 4430 c-29 -12 -56 -65 -47 -93 3 -12 16 -32 27 -44 44 -49 137
        -14 137 52 0 47 -8 64 -41 80 -32 17 -44 18 -76 5z"/>
        <path d="M3464 4086 c-57 -25 -66 -105 -16 -142 28 -22 86 -17 110 9 62 66
        -11 169 -94 133z"/>
        <path d="M372 3050 c-46 -43 -37 -107 19 -139 51 -29 119 14 119 76 0 70 -87
        110 -138 63z"/>
        <path d="M3449 2630 c-29 -17 -43 -66 -29 -100 15 -37 37 -50 83 -50 69 0 105
        90 54 138 -25 23 -79 29 -108 12z"/>
        <path d="M3448 2286 c-49 -37 -41 -114 14 -142 81 -42 161 72 95 134 -26 25
        -82 29 -109 8z"/>
        <path d="M369 2361 c-38 -38 -38 -74 0 -112 38 -37 82 -39 116 -4 54 54 13
        145 -65 145 -12 0 -35 -13 -51 -29z"/>
        <path d="M390 1697 c-79 -40 -53 -157 35 -157 73 0 110 80 63 134 -28 31 -65
        40 -98 23z"/>
        <path d="M3443 828 c-30 -34 -36 -63 -19 -96 17 -34 65 -57 96 -47 62 20 82
        106 34 144 -36 28 -85 27 -111 -1z"/>
        <path d="M3444 486 c-75 -64 6 -177 94 -132 50 26 58 91 17 131 -31 32 -75 32
        -111 1z"/>
        </g>
        </svg>
        `,
        'cms': `
        <?xml version="1.0" standalone="no"?>
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="18pt" height="18pt" viewBox="0 0 256.000000 256.000000"
         preserveAspectRatio="xMidYMid meet">
        
        <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M175 2471 c-69 -17 -116 -55 -151 -121 -18 -33 -19 -76 -22 -809 -2
        -529 1 -792 8 -827 15 -70 57 -126 117 -155 47 -24 52 -24 400 -27 l351 -3
        -50 -149 -49 -149 -141 -3 c-133 -3 -141 -4 -159 -27 -24 -30 -24 -62 0 -92
        l19 -24 775 -3 c772 -2 774 -2 800 19 37 28 37 80 1 108 -24 19 -40 21 -158
        21 l-132 0 -47 143 c-26 78 -47 146 -47 150 0 4 151 7 336 7 236 0 349 4 378
        12 54 16 128 90 144 144 16 56 17 1579 1 1639 -15 53 -91 129 -144 144 -46 12
        -2178 14 -2230 2z m2212 -163 l23 -21 0 -654 0 -653 -1130 0 -1130 0 0 652 0
        652 22 23 21 23 1086 0 1085 0 23 -22z"/>
        </g>
        </svg>
        `,
       'collection':`
       <?xml version="1.0" standalone="no"?>
       <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="18pt" height="18pt" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">
       
       <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
       fill="#000000" stroke="none">
       <path d="M815 4467 c-85 -33 -141 -92 -165 -173 -8 -28 -10 -356 -8 -1194 3
       -1119 4 -1156 22 -1194 25 -50 72 -97 122 -122 37 -18 70 -19 798 -22 849 -3
       820 -5 893 67 21 20 48 55 58 76 20 39 20 68 20 1215 0 1142 -1 1176 -19 1214
       -25 50 -72 97 -122 122 -37 18 -69 19 -804 21 -569 1 -773 -1 -795 -10z m1556
       -176 l29 -29 0 -1142 0 -1142 -29 -29 -29 -29 -742 0 -742 0 -29 29 -29 29 0
       1142 0 1142 29 29 29 29 742 0 742 0 29 -29z"/>
       <path d="M2739 4031 c-16 -16 -29 -36 -29 -45 0 -26 21 -63 44 -75 11 -6 185
       -56 386 -111 201 -56 386 -108 412 -116 53 -17 78 -48 78 -97 0 -32 -555
       -2122 -586 -2204 -11 -30 -24 -46 -50 -58 -39 -18 41 -37 -769 180 -278 74
       -479 123 -493 120 -34 -8 -62 -42 -62 -74 0 -63 -6 -61 625 -233 629 -171 676
       -180 756 -146 48 21 106 74 128 117 11 20 152 533 315 1141 310 1157 314 1177
       282 1254 -17 42 -80 113 -117 131 -30 16 -848 245 -874 245 -9 0 -30 -13 -46
       -29z"/>
       <path d="M3849 3141 c-26 -21 -32 -79 -11 -104 7 -8 164 -102 348 -208 352
       -203 371 -218 362 -278 -5 -32 -1100 -1936 -1134 -1971 -49 -51 -57 -48 -579
       255 -264 153 -508 292 -541 310 -67 36 -100 36 -130 3 -22 -23 -23 -83 -3
       -103 14 -14 987 -578 1072 -621 99 -51 207 -38 285 33 32 29 171 262 598 1003
       306 531 565 983 575 1005 21 46 24 120 7 178 -25 82 -79 123 -435 327 -225
       129 -341 190 -361 190 -17 0 -40 -8 -53 -19z"/>
       </g>
       </svg>
       `,
       'menu':`
       <?xml version="1.0" standalone="no"?>
       <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="18pt" height="18pt" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">
       
       <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
       fill="#000000" stroke="none">
       <path d="M1456 4340 c-107 -33 -185 -152 -173 -265 9 -89 61 -165 142 -208
       l40 -22 1735 0 1735 0 40 22 c92 49 139 127 140 228 0 102 -48 183 -138 232
       -30 17 -129 18 -1762 20 -952 1 -1743 -2 -1759 -7z"/>
       <path d="M200 2809 c-90 -18 -182 -113 -196 -204 -10 -67 3 -129 39 -182 42
       -62 99 -101 167 -113 37 -7 843 -9 2385 -8 2110 3 2333 5 2365 19 52 24 97 65
       128 118 23 39 27 57 27 121 0 64 -4 82 -27 121 -31 53 -76 94 -128 118 -32 15
       -254 16 -2375 17 -1325 1 -2360 -2 -2385 -7z"/>
       <path d="M2479 1266 c-222 -79 -234 -378 -19 -475 44 -20 59 -21 1246 -21
       1337 0 1256 -4 1332 69 57 56 77 104 77 186 -1 52 -6 81 -22 111 -31 58 -104
       119 -158 133 -68 17 -2407 15 -2456 -3z"/>
       </g>
       </svg>
       `,
       'user-group':`
       <?xml version="1.0" standalone="no"?>
       <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="18pt" height="18pt" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">
       
       <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
       fill="#000000" stroke="none">
       <path d="M1175 4473 c-279 -39 -529 -245 -619 -511 -86 -252 -39 -520 128
       -728 l55 -68 -57 -31 c-374 -200 -616 -553 -672 -978 -14 -112 -13 -672 2
       -700 25 -47 19 -46 486 -47 l442 0 0 -357 c1 -377 1 -377 47 -401 31 -17 3115
       -17 3146 0 46 24 46 24 47 401 l0 357 443 0 c466 1 460 0 485 47 15 28 16 584
       2 701 -52 411 -299 775 -658 970 l-71 38 55 68 c239 298 225 718 -33 997 -209
       225 -520 304 -810 205 -133 -45 -193 -90 -193 -143 0 -30 51 -83 80 -83 11 0
       38 9 60 20 194 99 385 104 565 14 300 -148 417 -515 259 -815 -66 -125 -194
       -233 -332 -281 -56 -19 -87 -23 -187 -23 -66 0 -143 4 -172 9 -48 8 -53 7 -77
       -17 -18 -18 -26 -37 -26 -62 0 -64 50 -90 198 -104 136 -13 239 5 414 74 l46
       19 65 -28 c159 -67 344 -219 450 -367 55 -78 117 -199 146 -287 48 -141 53
       -185 58 -490 l5 -292 -391 0 c-215 0 -391 4 -391 9 0 22 -33 192 -46 241 -83
       294 -227 535 -447 747 -105 101 -277 225 -388 279 l-57 27 64 80 c251 318 268
       766 41 1102 -111 164 -281 296 -465 360 -501 175 -1046 -97 -1206 -602 -92
       -293 -32 -619 158 -860 l64 -80 -37 -17 c-20 -10 -74 -41 -121 -68 -281 -169
       -494 -403 -635 -702 -65 -136 -99 -242 -127 -397 l-22 -119 -388 0 -388 0 0
       248 c0 363 23 483 133 694 116 224 330 420 549 503 l47 17 88 -35 c112 -45
       180 -58 293 -58 138 0 249 28 264 66 13 36 6 76 -20 102 -24 24 -29 25 -77 17
       -29 -5 -106 -9 -172 -9 -146 0 -217 21 -331 97 -167 112 -258 287 -257 498 1
       224 127 425 328 524 180 90 371 85 565 -14 53 -27 76 -25 111 9 70 71 10 141
       -171 199 -112 36 -240 49 -345 35z m1504 -174 c162 -26 306 -101 426 -223 95
       -97 153 -194 191 -321 36 -122 39 -292 6 -408 -63 -222 -205 -394 -407 -492
       -117 -57 -203 -77 -335 -77 -132 0 -218 20 -335 77 -202 98 -344 270 -407 492
       -33 116 -30 286 6 408 38 127 96 224 191 321 180 182 421 263 664 223z m-546
       -1589 c142 -75 256 -102 427 -102 170 0 285 27 427 101 l95 50 56 -23 c162
       -68 303 -166 448 -311 185 -184 313 -408 375 -652 43 -170 49 -248 49 -620 l0
       -343 -1450 0 -1450 0 0 343 c0 190 5 381 11 428 44 342 191 635 435 869 115
       111 201 170 353 247 68 35 124 63 126 63 1 0 45 -23 98 -50z"/>
       </g>
       </svg>
       `,
       'paint':`
       <?xml version="1.0" standalone="no"?>
       <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
        "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
       <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="18pt" height="18pt" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">
       
       <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
       fill="#000000" stroke="none">
       <path d="M4649 5101 c-99 -32 -169 -91 -216 -186 -11 -22 -91 -210 -178 -418
       -156 -372 -158 -377 -179 -362 -102 75 -180 111 -281 126 -36 6 -717 9 -1710
       7 -1476 -3 -1655 -5 -1695 -19 -94 -33 -163 -75 -224 -135 -69 -68 -106 -124
       -139 -212 l-22 -57 -3 -1675 c-2 -1210 0 -1692 8 -1735 39 -208 223 -390 430
       -425 46 -8 554 -10 1735 -8 l1670 3 57 22 c86 33 143 69 208 135 76 76 136
       193 150 293 6 42 10 533 10 1245 l0 1175 415 877 c346 728 418 888 427 944 32
       190 -85 367 -271 410 -74 18 -124 17 -192 -5z m186 -220 c16 -9 38 -32 49 -51
       44 -75 62 -32 -504 -1225 -287 -605 -523 -1102 -525 -1104 -8 -7 -157 64 -162
       76 -4 11 876 2132 929 2238 42 86 129 113 213 66z m-967 -859 c52 -28 119 -87
       127 -112 4 -12 -61 -180 -177 -457 l-183 -437 -5 269 c-5 289 -7 299 -59 327
       -19 11 -292 13 -1440 13 -1352 0 -1417 -1 -1443 -18 -14 -10 -31 -32 -37 -49
       -7 -20 -11 -183 -11 -470 0 -373 2 -443 15 -468 33 -64 -59 -60 1430 -60 745
       0 1355 -2 1355 -5 0 -2 -19 -50 -41 -105 l-42 -100 -454 0 c-487 0 -502 -1
       -537 -51 -14 -21 -16 -90 -16 -685 l0 -662 -37 -30 c-21 -16 -64 -46 -96 -67
       -110 -69 -111 -139 -4 -225 76 -62 179 -113 283 -142 66 -18 106 -22 249 -22
       154 0 180 2 275 27 139 36 290 111 354 174 178 176 276 485 221 696 l-15 57
       231 488 c127 268 233 489 235 491 2 3 3 -436 2 -975 l-3 -979 -23 -47 c-28
       -56 -92 -122 -143 -148 -20 -11 -65 -24 -100 -30 -45 -8 -545 -10 -1689 -8
       l-1625 3 -67 33 c-76 37 -120 82 -157 162 l-26 55 -3 1625 c-2 1146 0 1644 8
       1689 17 97 54 157 131 214 81 59 -36 56 1794 54 l1680 -2 43 -23z m-458 -932
       l0 -320 -1277 2 -1278 3 -3 318 -2 317 1280 0 1280 0 0 -320z m347 -798 c-2
       -4 -73 -153 -157 -332 -84 -179 -156 -329 -160 -333 -4 -5 -34 9 -67 30 l-60
       38 128 305 c71 168 135 322 144 343 l15 37 80 -40 c45 -22 79 -44 77 -48z
       m-501 -179 c-3 -10 -39 -97 -79 -193 l-74 -175 -62 -7 c-182 -20 -348 -136
       -454 -318 l-27 -45 0 378 0 377 351 0 c329 0 350 -1 345 -17z m-61 -604 c72
       -27 149 -89 176 -141 57 -112 21 -316 -82 -470 -68 -100 -161 -156 -330 -200
       -156 -39 -397 -26 -501 28 l-28 15 52 45 c119 103 158 164 221 350 56 163 77
       204 135 267 109 117 231 153 357 106z"/>
       <path d="M730 2344 c-43 -15 -64 -32 -77 -61 -10 -26 -13 -178 -13 -793 0
       -835 -2 -803 60 -835 44 -23 1116 -23 1160 0 62 32 60 0 60 833 0 515 -3 769
       -11 790 -6 17 -23 39 -37 49 -25 17 -64 18 -577 20 -302 1 -557 -1 -565 -3z
       m978 -851 l-3 -638 -425 0 -425 0 -3 638 -2 637 430 0 430 0 -2 -637z"/>
       </g>
       </svg>
       
       `,
       'gallery':`
       <?xml version="1.0" standalone="no"?>
       <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
        "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
       <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="18pt" height="18pt" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">
       
       <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
       fill="#000000" stroke="none">
       <path d="M1010 4789 c-89 -15 -214 -63 -295 -114 -182 -113 -300 -270 -368
       -488 l-22 -72 -3 -1510 c-2 -1039 1 -1532 8 -1580 54 -354 341 -641 695 -695
       48 -7 537 -10 1570 -8 l1500 3 76 22 c300 85 510 290 601 585 l23 73 3 1510
       c2 1039 -1 1532 -8 1580 -34 220 -164 429 -344 554 -84 58 -152 90 -259 124
       l-72 22 -1525 1 c-884 1 -1548 -2 -1580 -7z m3079 -324 c183 -43 333 -193 376
       -376 13 -55 15 -220 15 -1179 l0 -1114 -377 376 c-409 407 -400 400 -490 383
       -33 -6 -84 -52 -393 -361 l-355 -354 -715 714 c-633 633 -719 715 -752 721
       -91 17 -87 20 -435 -328 l-323 -322 0 700 c0 771 0 772 62 888 64 119 195 220
       331 253 41 10 372 12 1522 13 1278 1 1478 -1 1534 -14z m-1599 -2700 l1125
       -1125 -1260 0 c-1388 0 -1327 -2 -1448 62 -76 41 -164 129 -205 205 -60 113
       -62 132 -62 728 l0 540 357 357 c197 197 360 358 363 358 3 0 511 -506 1130
       -1125z m1577 -7 l413 -413 0 -122 c0 -146 -11 -205 -55 -298 -58 -121 -173
       -219 -308 -261 l-58 -18 -484 484 -485 485 277 277 c153 153 280 278 283 278
       3 0 191 -186 417 -412z"/>
       <path d="M3495 4151 c-237 -52 -413 -237 -447 -470 -52 -354 242 -668 598
       -638 229 20 413 169 490 397 14 40 19 84 19 160 0 76 -5 120 -19 160 -80 238
       -271 386 -511 395 -49 2 -108 0 -130 -4z m179 -323 c86 -26 166 -136 166 -228
       0 -124 -116 -240 -240 -240 -63 0 -114 23 -165 75 -102 101 -102 229 0 330 70
       71 145 90 239 63z"/>
       </g>
       </svg>`
  });
}

ngOnInit(){
    let t = this;  
    this.eventService.onChange.subscribe({
      next: (event: MyServiceEvent) => {
        console.log("Event working in pages.components.ts " + event.eventId); 
        if(event.eventId == 909){
          this.loading = true;
        }  
        if(event.eventId == 808){
          this.loading = false;
        }
      }
   })

   this.formServie.activatedAjaxFormSubmit('form.dashboard',3004);
  //  $(document).ready(function(){
  //     console.log("Ready on page.componet.ts");
  //     $(document).on('submit',"form.dashboard",function(e){
  //       var form = $(this);
  //       $(form).find('.role_status').show();
  //       $(form).find('.role_submit').hide();
  //       let action = form.attr('action');
  //       if(!form.attr('action').startsWith("http")){
  //         action = t.apiService.route+ action;
  //       }
  //     $(".error_message").hide();
  //     e.preventDefault();

  //     var fData = new FormData(this);
  //     $.ajax({
  //         url   : action,
  //         type  : form.attr('method'),
  //         data  : fData,
  //         contentType: false,
  //         processData: false,
  //         error:function(error){
  //           $(form).find('.role_status').hide();
  //           $(form).find('.role_submit').show();
  //         },            
  //         success: function(response){
  //           console.log('pages.component form submitted!');
  //           $(form).find('.role_status').hide();
  //           $(form).find('.role_submit').show();
  //             // console.log(response);
  //             if("errors" in response){
  //                 $.each(response['errors'], function(key:any,value){
  //                     key = key.replace(/\./g,"-");
  //                     $(form).find("." + key + "-err").show();
  //                     $(form).find("." + key + "-err").html(value);
  //                     $(form).find("#" + key + "-err").show();
  //                     $(form).find("#" + key + "-err").html(value[0]);
  //                 });
  //               }
  //             else {
  //               if(response.status){
  //                 if(!$(form).hasClass("exclude_clear")){
  //                   $(form)[0].reset();
  //                 }
  //                 t.toaster.success(response.message);
  //                 t.eventService.addEvent("Form Submitted",3004);
  //                 setTimeout(function(){
  //                   t.refDetectChange.detectChanges();
  //                 },500);
  //               }
  //               else{
  //                 t.toaster.error(response.message);
  //                 setTimeout(function(){
  //                   t.refDetectChange.detectChanges();
  //                 },500);
  //               }
  //             }
  //             t.refDetectChange.detectChanges();
  //             setTimeout(function(){
  //               t.refDetectChange.detectChanges();
  //             },500);   
                       
  //           }
  //           });
  //     return false;
  //    });
  //   });
  }

  ngOnDestroy(){
    // $(document).off('submit','form');
    this.formServie.dectivatedAjaxFormSubmit('form.dashboard');
    // this.channel.unsubscribe();
  }

}
