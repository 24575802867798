import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { map } from 'rxjs/operators';
import { ServerResponse } from 'http';
import { AuthService } from "../auth/auth.service";
import { Location } from '@angular/common';
import { LoadingController } from '@ionic/angular';
import { EventService, MyServiceEvent } from '../emmiter/event.service';
import * as $ from "jquery";
@Injectable({
  providedIn: 'root'
})

export class ApiService {

  route = "";
  domain = "";
  isBrowser: boolean = false;
  placeholder = '';
  loading: any;

  constructor(@Inject(PLATFORM_ID) private platformId, public loadingController: LoadingController, private eventLis: EventService, private _location: Location, private auth: AuthService, private http: HttpClient) {
    this.domain = "http://admin.localhost";
    this.route = "https://codefree.in:8089";
    this.ajaxSetting();

    if (isPlatformBrowser(platformId)) {
      this.isBrowser = true;
    }
  }

  ajaxSetting() {
    $.ajaxSetup({
      headers: {
        Authorization: `Bearer ${this.auth.getToken()}`,
        "project_id": `${this.auth.getProjectID()}`,
        "project_name": `${this.auth.getProjectName()}`
      },
      xhrFields: {
        withCredentials: true
      },
      dataFilter: function (data, type) {
        return data;
      }
    });
  }

  ngOnInit() {
    this.eventLis.onChange.subscribe({
      next: async (event: MyServiceEvent) => {
        if (event.eventId == 5000) {
          this.loading = await this.loadingController.create({
            spinner: null,
            duration: 5000,
            message: 'Please wait...',
            translucent: true,
            cssClass: 'custom-class custom-loading'
          });
          return await this.loading.present();
        }
      }
    })

    this.eventLis.onChange.subscribe({
      next: async (event: MyServiceEvent) => {
        if (event.eventId == 6000) {
          return await this.loading.dismiss();
        }
      }
    })
  }

  backClicked() {
    this._location.back();
  }

  getData(path: string): Observable<any> {
    this.eventLis.addEvent('show loader', 5000);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true, observe: 'response' as 'response'
    };

    return this.http.get(this.route + path, httpOptions).pipe(map(response => {
      let result: any = response;
      this.eventLis.addEvent('hide loader', 6000);
      return result.body;
    }));
  }

  
  getFileData(path: string): Observable<any> {
    this.eventLis.addEvent('show loader', 5000);
    const httpOptions = {
      headers: new HttpHeaders(),
      withCredentials: true, observe: 'response' as 'response'
    };

    return this.http.get(this.route + path, httpOptions).pipe(map(response => {
      let result: any = <ServerResponse><unknown>response;
      this.eventLis.addEvent('hide loader', 6000);
      console.log(response);
      return result.body;
    }));
  }

  getDomainData(path: string): Observable<any> {
    return this.http.get(path);
  }

  chunk(arr: any, size: any) {
    var chunks = [],
      i = 0,
      n = Object.keys(arr).length;
    if (n == 0) {
      return [];
    }
    while (i < n) {
      chunks.push(arr.slice(i, i += size));
    }

    return chunks;

    let result = arr.reduce((rows: any, key: any, index: any) => (index % size == 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows, []);

    return result;
  }

  geturlData(path: string): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' }),
      withCredentials: false,
      observe: 'response' as 'response'
    };

    return new Promise(resolve => {
      $.get(this.route + path, function (data, status) {
        console.log("Data: " + data + "\nStatus: " + status);
        resolve(data);
      });
    });
  }

  // postData(path: string, data: any): Promise<any> {
  //   const httpOptions = {
  //     headers: new HttpHeaders(
  //     { 'Content-Type': 'application/json' }),
  //     withCredentials: true,
  //     observe: 'response' as 'response'
  //   };

  //   return new Promise(resolve => {
  //     $.post(this.route+path,data, function(response){ 
  //       resolve(response);
  //     });
  //   });

  //   // return this.http.post(this.route + path, data, httpOptions).pipe(map(response => {
  //   //   let result: any = <ServerResponse><unknown>response;
  //   //   return result.body;
  //   // }));
  // }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  envokeForms() {

  }

  postHttpData(path: string, data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' }),
      withCredentials: true,
      observe: 'response' as 'response'
    };
    this.eventLis.addEvent('show loader', 5000);

    return this.http.post(this.route + path, data, httpOptions).pipe(map(response => {
      let result: any = response;
      this.eventLis.addEvent('show loader', 6000);
      return result.body;
    }));
  }

  uploadImage(path: string, image: File): Observable<any> {
    const formData = new FormData();
    formData.append('image', image);
    return this.http.post(this.route + path, formData);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
