import { Injectable, ModuleWithProviders, NgModule } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { finalize } from "rxjs/operators";
import { AuthService } from "../auth/auth.service";
import { tap, catchError } from "rxjs/operators";
import { EventService } from "../emmiter/event.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { HeaderComponent } from "../../@theme/components";
import { ApiService } from "../api/api.service";

@Injectable()
@NgModule({})
export class CustomHttpInterceptor implements HttpInterceptor {
 
    constructor(private auth:AuthService,private apiService:ApiService,private eventServie:EventService,private router:Router,private toastr:ToastrService) {
    
     }

     static forRoot(): ModuleWithProviders<any> {
      return {
          ngModule: CustomHttpInterceptor,
          providers: [{
            provide: HTTP_INTERCEPTORS,
            useClass: CustomHttpInterceptor,
            multi: true
          } ],
        
      };
    }

  

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      request = request.clone({headers: request.headers.set("Authorization", `Bearer ${this.auth.getToken()}`)
      .set("project_id",`${this.auth.getProjectID()}`)
      .set( "project_name",`${this.auth.getProjectName()}`),
    });
  
        this.eventServie.addEvent("m",909);
      // return next.handle(request);
      return next.handle(request).pipe(
        tap((evt:any) => {
            if (evt instanceof HttpResponse) { 
                if(evt.body && evt.body.status === 403){
                  this.apiService.getData('/logout')
                  .subscribe((res:any)=>{
                    localStorage.removeItem("token");
                      this.router.navigateByUrl('/login');
                  },
                  (err:any)=>{ 
                  });
                }
            }
        }),
        catchError((err: any) => {
          console.log(err);
          this.eventServie.addEvent("m",6000);
          if(err.error.message == "Token has expired"){
            localStorage.removeItem("token");
            this.apiService.getData('/logout')
    .subscribe((res:any)=>{
      localStorage.removeItem("token");
        this.router.navigateByUrl('/login');
    },
    (err:any)=>{
      console.log(err);
    });
          }
          if(!navigator.onLine){
              this.toastr.error("No Internet connection.");
          }         
            if(err instanceof HttpErrorResponse) {
            return of(err);
        }}))
    }
}