import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as $ from "jquery";
import { ApiService } from '../../../shared/api/api.service';
@Component({
  selector: 'ngx-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss']
})
export class PermissionComponent implements OnInit {

  module :any;
  constructor(private apiService:ApiService,private activatedRoute:ActivatedRoute){
    this.module = activatedRoute.snapshot.params.name;
   }

   backClicked(){
    this.apiService.backClicked();
  }

  ngOnInit(): void {
    let that =  this;
    var url  = that.apiService.route+"/module/edit/access/"+this.module;
    console.log(url);
    $.get(url,function(responseTxt){
      console.log(responseTxt);
      $('#dialog_content').html(responseTxt);
    })
  }

}
