<ion-header>
  <ion-toolbar> 
<!-- <nav class="navbar navbar-expand-lg navbar-light bg-light"> -->
 <ion-buttons slot="start">
  <a *ngIf="show_new_project_option" (click)="toggleSidebar()" href="#" class="sidebar-toggle button pr-2">
    <nb-icon icon="menu-2-outline"></nb-icon>
  </a>

  <a class="navbar-brand nbb" href="#">
    CodeFree
    <!-- <img (click)="navigateHome()" height="50px" src="assets/logo.png" /> -->
  </a>
 </ion-buttons>

  <!-- <div class="flex-auto" id="navbarSupportedContent"> -->
    <!-- <ul _ngcontent-yhk-c255="" class="navbar-nav mr-auto"></ul> -->
    <ul class="mr-auto d-inline-flex float-right">
      <!-- <li> 
        <button *ngIf="data.active_project?.active === 0 && show_new_project_option" class="spectrum-Button spectrum-Button--outline spectrum-Button--accent spectrum-Button--sizeS mt-2 mr-1">
        <span class="dot red_dot"></span><span>Offline</span>
      </button> 
    </li>
    <li> 
      <button *ngIf="data.active_project?.active === -1 && show_new_project_option" (click)="publishPage()" class="spectrum-Button spectrum-Button--outline spectrum-Button--accent spectrum-Button--sizeS mt-2 mr-1">
      <span class="dot yellow_dot"></span><span>Changes</span>
    </button> 
  </li>
      <li> 
        <button *ngIf="data.active_project?.active === 1 && show_new_project_option" class="spectrum-Button spectrum-Button--outline spectrum-Button--staticWhite spectrum-Button--sizeS mt-2 mr-1">
        <span class="dot green_dot"></span><span>live</span>
      </button> </li>
     -->
      <!-- <li class="nav-item" *ngIf="show_new_project_option">
        <button
          class="spectrum-Button spectrum-Button--outline spectrum-Button--accent spectrum-Button--sizeS mt-2 mr-1"
          (click)="reloadComponent()">
          <span class="spectrum-Button-label"><i class="fa fa-refresh"></i></span>
        </button>
      </li> -->

      <!-- <li class="nav-item" *ngIf="show_new_project_option && user !== 0">
       
      </li> -->

      <!-- <li class="nav-item" *ngIf="user === 0">
        <button
          class="spectrum-Button spectrum-Button--outline spectrum-Button--accent spectrum-Button--sizeS mt-2 mr-1"
          (click)="moveTo('/register')">
          <span class="spectrum-Button-label">Register</span>
        </button>
      </li> -->

      <!-- <li class="nav-item" *ngIf="user === 0">
        <button
          class="spectrum-Button spectrum-Button--outline spectrum-Button--accent spectrum-Button--sizeS mt-2 mr-1"
          (click)="moveTo('/login')">
          <span class="spectrum-Button-label">Login</span>
        </button>
      </li> -->

      <!-- <li class="nav-item" *ngIf="show_new_project_option && user !== 0">
        <button
          class="spectrum-Button spectrum-Button--outline spectrum-Button--accent spectrum-Button--sizeS mt-2 mr-1"
          (click)="login_As_DM()">
          <span class="spectrum-Button-label">Content Manager</span>
        </button>
      </li> -->

    </ul>
    <ion-buttons slot="end">
      <button
      class="spectrum-Button spectrum-Button--outline spectrum-Button--accent spectrum-Button--sizeS mt-2 mr-1"
      (click)="dashboard()">
      <span class="spectrum-Button-label">Home</span>
    </button>
      <div class="dropdown" *ngIf="user !== 0">
         
        <button
          class="spectrum-Button spectrum-Button--outline spectrum-Button--accent spectrum-Button--sizeS mt-2 mr-1 mr-1"
          type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="spectrum-Button-label">Account</span>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <!-- <button class="dropdown-item">Billing</button> -->
          <button class="dropdown-item" (click)="logout()">Log out</button>
        </div>
      </div>
    </ion-buttons>
<!-- 
  </div>
</nav> -->

</ion-toolbar>
</ion-header>
<!-- <div class="loader" *ngIf="loading > 0"> -->
  <!-- <div class="loading_1"></div> -->
<!-- </div> -->

<!-- <div class="loader-wrapper" *ngIf="loading > 0">
  <div class="loader">
    <svg viewBox="0 0 80 80">
        <circle id="test" cx="40" cy="40" r="32"></circle>
    </svg>
  </div>
</div> -->



<div class="" *ngIf="building">
  <p class="text-center">Build worker running!</p>
</div>