import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PaymentComponent } from './payment/payment.component';
import { VerifyAccountComponent } from './modules/auth/verify-account/verify-account.component';

import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { FormComponent } from './modules/database-module/form/form.component';
import { PermissionComponent } from './modules/database-module/permission/permission.component';
import { DataTransactionComponent } from './modules/database-module/data-transaction/data-transaction.component';
import { ViewConfigComponent } from './modules/database-module/view-config/view-config.component';
import { VariableComponent } from './modules/database-module/variable/variable.component';
import { FilterComponent } from './modules/database-module/filter/filter.component';
import { PagesComponent } from './modules/pages/pages.component';
import { SubscriptionUsagesComponent } from './components/subscription-usages/subscription-usages.component';

export const routes: Routes = [
  {
    path: 'userarea',
    loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule)
  },

  {
    path: 'login',
    loadChildren: () => import('./modules/auth/login/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./modules/auth/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'verify-account',
    component:VerifyAccountComponent,
  }, 
   {
    path: 'module',
    // component: DashboardComponent,
    // path: '',
  component: PagesComponent,
    children: [
      {
        path: 'form/:type/:name',
        component: FormComponent
      },
      {
        path: 'access/:name',
        component: PermissionComponent
      },
      {
        path: 'data_transaction/:name',
        component: DataTransactionComponent
      },
      {
        path: 'view-config/:name',
        component: ViewConfigComponent
      },
      {
        path: 'variable/:name',
        component: VariableComponent
      },
      {
        path: 'filter/:name',
        component: FilterComponent
      }
    ]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'billing',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'pay',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'data-manager',
    loadChildren: () => import('../data-manager/app/datamanager.module').then(m => m.DatamanagerModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./modules/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  { path: '', redirectTo: '/dashboard/home', pathMatch: 'full' },
  { path: '**', redirectTo: 'userarea' },
];

const config: ExtraOptions = { 
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})

export class AppRoutingModule {
}