import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../shared/api/api.service';
import { EventService, MyServiceEvent } from '../../../shared/emmiter/event.service';

@Component({
  selector: 'ngx-variable',
  templateUrl: './variable.component.html',
  styleUrls: ['./variable.component.scss']
})
export class VariableComponent implements OnInit {

  name = "";
  table_data = {vrs:[],fields:[],module:'',filters:[]};
  view_type = 'list';
  es: any;
  constructor(private apiService:ApiService,private route:ActivatedRoute,private eventService:EventService) {
    this.name = this.route.snapshot.paramMap.get("name");
    // alert(this.name);
   this.es =  this.eventService.onChange.subscribe({
    
      next: (event: MyServiceEvent) => {
          if(event.eventId == 303){
            this.loadDB();
          }
      }
    })
  }
  
  backClicked(){
    this.apiService.backClicked();
  }

  loadDB(){
    this.apiService.getData("/admin/dms/variable/"+this.name).subscribe((res:any)=>{
      this.table_data = res;
      this.view_type = 'list';
      console.log(res);
    });
  }

  ngOnInit(): void{
      this.loadDB();
  }

  ngOndestroy(){
    this.es.unsubscribe();
  }


}
