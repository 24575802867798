import { EventEmitter, Injectable, ModuleWithProviders, NgModule } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class MyServiceEvent {
  message!: string;
  eventId!: number;
}
@NgModule({})
export class EventService {

  constructor() {
  }

  static forRoot(): ModuleWithProviders<any> {
    return {
        ngModule: EventService,
        providers: [EventService]
    };
  }

  public onChange: EventEmitter<MyServiceEvent> = new EventEmitter<MyServiceEvent>();

  addEvent(message: string, eventId: number) {
    console.log("Subscribed",message,eventId);
    this.onChange
    // .subscribe((res:any)=>{
    //   console.log(res,'Emmited!');
    // }); 
    .emit({ message: message, eventId: eventId });
  }

}
