import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Content',
    icon: { icon: 'cms', pack: 'nav-bar' },
    link: '/userarea/pages',
    home: true
  },
  {
    title: 'Database',
    icon:  { icon: 'database', pack: 'nav-bar' },
    link: '/userarea/database'
  },
  {
    title: 'Collections',
    icon:  { icon: 'collection', pack: 'nav-bar' },
    link: '/userarea/collection'
  },
  {
    title: 'Navigation',
    icon:{ icon: 'menu', pack: 'nav-bar' },
    link: '/userarea/menu'
  },
  {
    title: 'User Groups',
    icon:{ icon: 'user-group', pack: 'nav-bar' },
    link: '/userarea/user-groups'
  },
  {
    title: 'Gallery',
    icon:{ icon: 'gallery', pack: 'nav-bar' },
    link: '/userarea/gallery'
  },
  {
    title: 'Theming',
    icon: { icon: 'paint', pack: 'nav-bar' },
    link: '/userarea/styling'
  },
  // {
  //   title: 'Publish',
  //   icon: 'home-outline',
  //   link: '/userarea/publish'
  // }
];
