import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../shared/api/api.service';

@Component({
  selector: 'ngx-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss']
})
export class VerifyAccountComponent implements OnInit {

  otp:any;

  constructor(private apiService:ApiService,private router:Router,private toastr:ToastrService){

   }

  ngOnInit(): void {
   this.sendOtp();
  }

  sendOtp(){
    let email  = localStorage.getItem('email');
    this.apiService.postHttpData("/request_otp",{email:email}).subscribe((res:any)=>{
      if(res.status){
        this.toastr.success(res.message);
      }else{
        this.toastr.error(res.message);
      }
    },(error:any)=>{

    })
  }

  reset(){
    let email  = localStorage.getItem('email');
    this.apiService.postHttpData("/verify_account",{email:email,otp:this.otp}).subscribe((res:any)=>{
      if(res.status){
        this.toastr.success(res.message);
        this.router.navigateByUrl("/login");
      }else{
        this.toastr.error(res.message);
      }
    },(error:any)=>{

    })
  }

}
