import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as $ from "jquery";
import { ApiService } from '../../../shared/api/api.service';
@Component({
  selector: 'ngx-view-config',
  templateUrl: './view-config.component.html',
  styleUrls: ['./view-config.component.scss']
})
export class ViewConfigComponent implements OnInit {

  module :any;
  constructor(private apiService:ApiService,private activatedRoute:ActivatedRoute){
    this.module = activatedRoute.snapshot.params.name;
   }
   
  backClicked(){
    this.apiService.backClicked();
  }

  ngOnInit(): void {
    let that =  this;
    var url  = that.apiService.route+"/module/edit/view/"+this.module;
    console.log(url);
    $.get(url,function(responseTxt){
      console.log(responseTxt);
      $('#dialog_content').html(responseTxt);
    });

    $(document).on('change','[name="mode"]',function(){
      if($(this).val() === 'TABLE'){
        $("#table_view").show();
      }
      else{
        $("#table_view").hide();
      }
    });
  }

  ngOnDestroy(){
    $(document).off('change','[name="mode"]');
  }

}
