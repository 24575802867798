<section class="ftco-section">
    <div class="container">
        <div class="row justify-content-center mb-5 pb-5 mt-5">
            <div class="col-lg-4">
                <div class="card">
    <div class="body m-4">
        <h2 class="jn-bg w3-padding text-center">Verify OTP</h2>

            <div class="form-group">
                <input type="number" class="form-control" name="otp" [(ngModel)]="otp" placeholder="Otp" required autofocus>                                       
            </div>
            
            <div class="form-group pt-2">
                <button  (click)="reset()" class="float-right  spectrum-Button spectrum-Button--outline spectrum-Button--accent spectrum-Button--sizeS mr-1">
                    <span class="spectrum-Button-label">Submit</span>
                  </button>
            </div>
            <div class="row pt-2 align-center">
                <a routerLink="/login" class="fg-cyan jn-content-center">Sign In!</a>
            </div>
    </div>
</div>
</div></div></div></section>