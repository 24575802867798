import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AnalyticsService } from './gtag/analytics.service';
import { ApiService } from './shared/api/api.service';
import { EventService, MyServiceEvent } from './shared/emmiter/event.service';
import { AdMob } from '@capacitor-community/admob';

declare const window:any;
// declare const $:any;
import * as $ from "jquery";
@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})

export class AppComponent implements OnInit {

  constructor(private refDetectChange:ChangeDetectorRef,
    private eventService:EventService,private toaster:ToastrService,private apiService:ApiService,private analyticsService:AnalyticsService) {
  }

  ngOnInit(): void {    
    // this.analyticsService.startTrackerWithId('G-YRCWH0BEL3');
    // window.console.log = function() {};
  // AdMob.initialize({
  //   requestTrackingAuthorization: true
  // });  
}

 ngOnDestroy(){
      console.log("Leaving on app.component");
 }
  
}
