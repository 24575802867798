<div class="row">
    <div class="col-md-6 offset-md-3 col-xs-12">
        <div class="row">
            <div class="col-md-8 col-sm-12 col-xs-12">
                <div class="info-box-4 card hover-expand-effect">
                    <!-- <div class="icon"> -->
                        <!-- <img src="assets/images/blue-disk-drive.png" style="max-width:70px" /> -->
                        <!-- <progress id="file" value="32" max="100"> 32% </progress> -->
                    <!-- </div> -->
                    <div class="content">
                        <div class="text">Total Disk</div>
                        <div class="text">  <progress id="file" value="32" max="100"> 32% </progress></div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="info-box-4 card">
                    <div class="icon">

                    </div>
                    <div class="content">
                        <div class="text w3-margin-bottom">Free Plan</div>
                        <button routerLink="/pricing"
                            class="w3-margin-top mt-2 w3-round-medium btn warning more jn-content-center">Upgrade</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

