import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../shared/api/api.service';
import { EventService, MyServiceEvent } from '../../../shared/emmiter/event.service';
import * as jQuery from "jquery";
@Component({
  selector: 'ngx-data-transaction',
  templateUrl: './data-transaction.component.html',
  styleUrls: ['./data-transaction.component.scss']
})

export class DataTransactionComponent implements OnInit {

  module = "";
  table_data = {dts:[],fields:[],filters:[]};
  view_type = 'list';
  es: any;

  constructor(private apiService:ApiService,private route:ActivatedRoute,private eventService:EventService) {
    this.module = this.route.snapshot.paramMap.get("name");
    this.es =  this.eventService.onChange.subscribe({
      next: (event: MyServiceEvent) => {
          if(event.eventId == 303 || event.eventId == 3004){
            this.loadDB();
          }
      }
    })
  }

  backClicked(){
    this.apiService.backClicked();
  }

  loadDB(){
    this.apiService.getData("/admin/dms/data_transaction/"+this.module).subscribe((res:any)=>{
      this.table_data = res;
      this.view_type = 'list';
      console.log(res);
    });
  }

  // '/edit/d_transaction/'+module+'/'+item.key"
  editRule(key) {
    let that = this;
    that.view_type = 'edit';
    jQuery.get(that.apiService.route + "/edit/d_transaction/" + that.module + "/" + key, function (data, status) {
      console.log(data['order']);
      // console.log(data);
      $(document).find("#edit").html(data);
    });
  }
  ngOnInit(): void{
      this.loadDB();
      $(document).on("change",".jn_select_switch",function(){
        var name = $(this).find(":selected").text();
        $(this).siblings(".hide_where").children().each(function (){
            if ($(this).hasClass(name)){
                $(this).show();
            }
            else{
                $(this).hide();
            }
        });
    });
  }

  ngOnDestroy(){
    $(document).off("change",".jn_select_switch");
    this.es.unsubscribe();
  }

}
