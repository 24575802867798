<div class="row">
    <div class="col-md-12 col-sm-12">
        <div class="tabbable card">
            <div class="modern_header w-100">
                <a href="javascript:void(0);" (click)="backClicked()"
                    class=" mr-1 spectrum-Button spectrum-Button--fill spectrum-Button--sizeS spectrum-Button--staticWhite"
                    role="button" aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-arrow-left">&nbsp;Back</i>
                </a>
                <a href="javascript:void(0);" (click)="view_type = 'create'"
                    class=" mr-1 spectrum-Button spectrum-Button--fill spectrum-Button--sizeS spectrum-Button--staticWhite float-right"
                    role="button" aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-plus">&nbsp;New</i>
                </a>
            </div>

            <div class="card-body" *ngIf="view_type === 'list'">
                <p class="text-center">Filters</p>
                <ul class="col-md-6 mx-auto">
                    <li *ngFor="let item of table_data.filters | keyvalue"><b>Name:</b>{{item.key}} <button
                            class="spectrum-Button spectrum-Button--accent spectrum-Button--outline mb-2 spectrum-Button--sizeS float-right"
                            (click)="edit_filter(item.key)">Edit</button>
                        <p class="m-0"><b>Order:</b> {{item.value.order}}</p>
                        <p class="m-0"><strong>Rules:</strong></p>
                        <table>
                            <!-- <thead class="bg-black">
                                <tr> -->
                            <!-- <th>whereType</th>
                                    <th>Left</th>
                                    <th>Operator </th> -->
                            <!-- <th>Rules</th>
                                </tr>
                            </thead> -->
                            <tbody>
                                <tr *ngFor="let i of item.value.rows | keyvalue">
                                    <td class="bg-grey p-1">{{i.value.whereType}}</td>
                                    <td class="p-1" *ngIf="i.value.left !== undefined">{{i.value.left}}</td>

                                    <td class="bg-green p-1">{{i.value.operator}}</td>
                                    <td class="p-1" *ngIf="i.value.first !== undefined">
                                        {{i.value.first}},{{i.value.last}}</td>

                                    <td *ngIf="i.value.right !== undefined" class="p-1"> {{i.value.right}} </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr>
                    </li>
                </ul>
            </div>

            <div class="card-body p-4" *ngIf="view_type === 'create'">
                <div>
                    <a href="javascript:void(0);" (click)="view_type = 'list'"
                        class="spectrum-Button spectrum-Button--accent spectrum-Button--outline mb-2 spectrum-Button--sizeS mr-1"
                        role="button" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-arrow-left">&nbsp;List Filters</i>
                    </a>
                    <form action="/save_filter" method="post" class="dashboard">
                        <div class="row">
                            <input name="module" type="hidden" value="{{module}}">
                            <table class="table table-responsive">
                                <tr>
                                    <td>
                                        Filter Name
                                    </td>
                                    <td>
                                        <input name="filter_name" type="text" 
                                            placeholder="Filter Name">
                                        <p class="error_message filter_name-err w-100"></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Filter Title</td>
                                    <td>
                                        <input name="title" placeholder="Add remarkable filter title"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Result Order
                                    </td>
                                    <td>
                                        <div>
                                            <select name="order" class="jn_select_switch">
                                                <option>latest</option>
                                                <option>oldest</option>
                                                <option>inRandomOrder</option>
                                                <option>orderBy</option>
                                            </select>
                                            <p class="error_message order-err w-100"></p>
                                            <div class="hide_where flex jn-content-center">
                                                <select name="order_table" class="orderBy" style="display: none">
                                                    <option>Select Column</option>
                                                    <option *ngFor="let item of table_data.rows">{{item}}</option>
                                                </select>
                                                <p class="error_message order_table-err w-100"></p>
                                                <select name="order_by_acs_desc" class="orderBy" style="display: none">
                                                    <option>Ascending</option>
                                                    <option>Descending</option>
                                                </select>
                                                <p class="error_message order_by_acs_desc-err w-100"></p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div class="row fc">
                            <div class="col-md-4 col-sm-12" style="margin-top: 20px;">
                                <p  class="condition-count" [innerHTML]="'Condition: 1'"></p>
                                      <button class="float-right spectrum-Button spectrum-Button--negative spectrum-Button--outline mb-2 spectrum-Button--sizeS mr-1 rm_parent">X</button>

                                <div class="col-xs-8 col-xs-offset-2">
                                    <select name="rows[1][whereType]" class="col-xs-3 jn_select_switch">
                                        <option>where</option>
                                        <option>orWhere</option>
                                        <option>whereBetween</option>
                                        <option>orWhereBetween</option>
                                        <option>whereNotBetween</option>
                                        <option>orWhereNotBetween</option>
                                        <option>whereIn</option>
                                        <option>orWhereIn</option>
                                        <option>whereNotIn</option>
                                        <option>orWhereNotIn</option>
                                        <option>whereNull</option>
                                        <option>whereNotNull</option>
                                    </select>

                                    <div class="hide_where col-xs-9">
                                        <div
                                            class="  where orWhere whereColumn whereDate whereMonth whereDay whereYear whereTime whereBetween orWhereBetween whereNotBetween orWhereNotBetween whereIn orWhereIn whereNotIn orWhereNotIn">
                                            <select name="rows[1][left]">
                                                <option>Select Column</option>
                                                <option *ngFor="let item of table_data.rows">{{item}}</option>
                                            </select>
                                            <p class="error_message rows-1-left-err w-100"></p>
                                        </div>
                                        <div style="display: none"
                                            class=" whereBetween orWhereBetween whereNotBetween orWhereNotBetween">
                                            <input class="operator" name="rows[1][first]" type="number"
                                                placeholder="first value">
                                        </div>

                                        <div style="display: none"
                                            class=" whereBetween orWhereBetween whereNotBetween orWhereNotBetween">
                                            <input class="operator" name="rows[1][last]" type="number"
                                                placeholder="last value">
                                        </div>

                                        <div style="display: none"
                                            class="col-xs-8 whereIn orWhereIn whereNotIn orWhereNotIn">
                                            <!-- <div class="bootstrap-tagsinput">
                                                <input type="text" placeholder="values">
                                            </div> -->
                                            <input class="operator---" data-role="tagsinput" name="rows[1][right]"
                                                placeholder="values">
                                        </div>

                                        <div
                                            class=" where orWhere whereColumn whereDate whereMonth whereDay whereYear whereTime">

                                            <select name="rows[1][operator]">
                                                <option class="a-class a-text a-number a-email a-date a-editor">EQUALS
                                                </option>
                                                <option class="a-class a-text a-number a-email a-date a-editor">
                                                    STARTS_WITH</option>
                                                <option class="a-class a-text a-number a-email a-date a-editor">
                                                    ENDS_WITH</option>
                                                <option class="a-class a-text a-number a-email a-date a-editor">CONTAINS
                                                </option>
                                                <option class="a-class a-number a-date">LESS_THAN</option>
                                                <option class="a-class a-number a-date">GREATER_THAN</option>
                                                <option class="a-class a-number a-date">NOT_EQUALS</option>
                                                <option class="a-class a-number a-date">LESS_THAN_AND_EQUAL</option>
                                                <option class="a-class a-number a-date">GREATER_THAN_AND_EQUAL</option>
                                            </select>
                                            <p class="error_message rows-1-operator-err w-100"></p>
                                        </div>

                                        <div class=" where orWhere whereDate whereMonth whereDay whereYear whereTime">
                                            <input name="rows[1][right]" placeholder="Value">
                                        </div>

                                        <div class=" whereColumn whereNull whereNotNull" style="display: none;">
                                            <select name="rows[1][table_column]">
                                                <option>Select Column</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button type="button" class="more_filter spectrum-Button spectrum-Button--accent spectrum-Button--outline mb-2 mt-2 spectrum-Button--sizeS mr-1">+ Condition</button>

                        <input name="fvv" value="ddd" type="hidden">
                        <button
                            class="mr-2 ml-2 mt-2 spectrum-Button spectrum-Button--accent spectrum-Button--outline mb-2 spectrum-Button--sizeS save_filter"
                            type="submit">Create</button>
                    </form>
                </div>
            </div>
             <div class="row_filter_hidden hidden"> 
                <div class="col-md-4 col-sm-12" style="margin-top: 20px;">
                    <p [innerHTML]="'Condition {count}'"></p>
                    <button class="float-right spectrum-Button spectrum-Button--negative spectrum-Button--outline mb-2 spectrum-Button--sizeS mr-1 rm_parent">X</button>
                    <div class="col-xs-8 col-xs-offset-2">
                        <select name="rows[{count}][whereType]" class="pl-3 jn_select_switch">
                            <option>where</option>
                            <option>orWhere</option>
                            <option>whereBetween</option>edit
                            <option>orWhereBetween</option>
                            <option>whereNotBetween</option>
                            <option>orWhereNotBetween</option>
                            <option>whereIn</option>
                            <option>orWhereNotIn</option>
                            <option>whereNotIn</option>
                            <option>orWhereNotIn</option>
                            <option>whereNull</option>
                            <option>whereNotNull</option>
                            <option>whereColumn</option>
                            <option>whereDate</option>
                            <option>whereMonth</option>
                            <option>whereDay</option>
                            <option>whereYear</option>
                            <option>whereTime</option>
                        </select>

                        <div class="hide_where">
                            <div
                                class=" where orWhere whereNull whereNotNull whereColumn whereDate whereMonth whereDay whereYear whereTime">
                                <select name="rows[{count}][left]">
                                    <option>Select Column</option>
                                    <option *ngFor="let item of table_data.rows">{{item}}</option>
                                </select>
                                <p class="error_message rows-{count}-left-err w-100"></p>
                            </div>

                            <div style="display: none"
                                class=" whereBetween orWhereBetween whereNotBetween orWhereNotBetween">
                                <input class="operator" name="rows[{count}][first]" placeholder="first value">
                            </div>

                            <div style="display: none"
                                class=" whereBetween orWhereBetween whereNotBetween orWhereNotBetween">
                                <input class="operator" name="rows[{count}][last]" placeholder="last value">
                            </div>

                            <div style="display: none" class="col-xs-8 whereIn whereNotIn">
                                <input class="operator" data-role="tagsinput" name="rows[{count}][where_in]" placeholder="values">
                            </div>

                            <div
                                class=" where orWhere whereColumn whereDate whereMonth whereDay whereYear whereTime">
                                <input class="operator" name="rows[{count}][operator]" placeholder="condition">
                            </div>

                            <div class=" where orWhere whereDate whereMonth whereDay whereYear whereTime">
                                <input name="rows[{count}][right]" placeholder="Value">
                            </div>

                            <div class=" whereColumn" style="display: none;">
                                <select name="rows[{count}][table_column]">
                                    <option>Select Column</option>
                                    <option *ngFor="let item of table_data.rows">{{item}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <div class="card-body" *ngIf="view_type === 'edit'" id="edit">

            </div>
        </div>
    </div>
</div>