import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api/api.service';


@Injectable()
export class AuthService {
  constructor(private router:Router){

  }



  getProjectName() {
    return localStorage.getItem('project_name');
  }
  getProjectID(): string {
    return localStorage.getItem('project_id');
  }
  public getToken(): string {
    return localStorage.getItem('token');
}

public isAuthenticated(): boolean{
    const token = this.getToken();
    if(this.getToken){
        return true;
     }
    return false;
  }
}