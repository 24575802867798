import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../shared/api/api.service';
import { EventService, MyServiceEvent } from '../../../shared/emmiter/event.service';
import * as jQuery from "jquery";
declare const $: any;

@Component({
  selector: 'ngx-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  count = 1;
  view_type = 'list';
  module: any;
  table_data: any = { rows: [], filters: {} }
  es: any;
  constructor(private apiService: ApiService,
    private route: ActivatedRoute,
    private refScreen: ChangeDetectorRef,
    private eventService: EventService) {
    this.module = this.route.snapshot.paramMap.get("name");
    // alert(this.module);
    this.es = this.eventService.onChange.subscribe({
      next: (event: MyServiceEvent) => {
        if (event.eventId == 3004) {
          this.loadDB();
        }
      }
    })
  }

  backClicked() {
    this.apiService.backClicked();
  }

  loadDB() {
    this.apiService.getData("/admin/dms/filter/" + this.module).subscribe((res: any) => {
      console.log(res.filters);
      this.table_data = res;
      this.view_type = 'list';
      // alert(res.filters?.length);
      // if(res.filters?.length < 1 || res.filters?.length === undefined){
      //   this.view_type = 'create';
      // }
    });
  }

  edit_filter(item: any) {
    let that = this;
    that.view_type = 'edit';
    jQuery.get(that.apiService.route + "/get_filter/" + that.module + "/" + item, function (data, status) {
      console.log(data['order']);
      // console.log(data);
      $(document).find("#edit").html(data);
      that.count = $("#edit").find(".fc").children().length;
      that.refScreen.detectChanges();
    });
  }

  ngOnInit(): void {
    this.loadDB();
    this.count = 1;
    let that = this;

    $(document).on("click", ".back_to_fl", function () {
      that.view_type = 'list';
      this.loadDB();
      that.refScreen.detectChanges();
    });
    $(document).on("click", ".rm_parent", function () {
      $(this).parent().remove();
    });

    jQuery(document).on("click", ".more_filter", function () {
      that.count = that.count + 1;
      var v:any =  $(".row_filter_hidden").html();
      v = v.replace(/{count}/g, that.count);
      // $(v).insertBefore(this);
      $(this).siblings(".fc").append(v);
      $("[data-role='tagsinput']").tagsinput();
    });

    var single, list;

    jQuery(document).on("change",".jn_select_switch",function(){
      var name = $(this).find(":selected").text();
      $(this).siblings(".hide_where").children().each(function (){
          if ($(this).hasClass(name)){
              $(this).show();
          }
          else{
              $(this).hide();
          }
      });
  });

    jQuery(document).on("click", "input",function () {
      var a = $(this);
      var model = a.parent().attr("data-item");
      var m = a.closest(".tab-pane").attr("id");
      var m = a.closest(".tab-pane").attr("id");
      var ds = "";
      a.trigger("focus");
      var sugg = [];
      if (a.hasClass("operator")) {
        sugg = ["EQUALS", "NOT_EQUALS", "GREATER_THAN", "LESS_THAN", "STARTS_WITH", "ENDS_WITH", "CONTAINS"];
      }

      else if (a.hasClass("single")) {
        var data_src = a.attr("data-source");
        var j = a.attr("data-source", data_src);
        ds = "test." + data_src;
        // console.log(data_src);
        var sa = Object.keys(single[data_src]);
        sugg = sa;
      }
      else if (a.hasClass("filter-name")) {
        sugg = Object.keys(list[m]);
        ds = "";
      }
      var name = a.attr("name");
      a.autocomplete({
        source: sugg,
        minLength: 0,
        select: function (event, ui) {
          $(a).val(ds + ui.item.label);
          return false;
        },
        focus: function (event, ui) {
          $(event.target).val(ui.item.label);
          return false;
        },
      });
      a.autocomplete("search", "");
    });
  }

  ngOnDestroy(): void {
    // $(document).off('click', '.create_new_filter');
    jQuery(document).off("change",".jn_select_switch");
    $(document).off('click', '.more_filter');
    $(document).off('click', '.edit_filter');
    $(document).off('click', '.back_to_fl');
    $(document).off('click', '.rm_parent');
    $(document).off("click", "input");
    jQuery(document).off('click', '.more_filter');
    jQuery(document).off('click', '.edit_filter');
    jQuery(document).off('click', '.back_to_fl');
    jQuery(document).off('click', '.rm_parent');
    jQuery(document).off("click", "input");
    this.es.unsubscribe();
  }
}
